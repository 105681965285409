<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Nuevo import voucher</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="familias" @change="setEmpresas"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Marca
            <v-autocomplete v-model="data.empresa" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="empresasFiltradas.filter(emp => emp.familia === data.familia)
                " @change="setSucursal"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Sucursal
            <v-autocomplete v-model="data.sucursal" item-text="provincia" item-value="id" hide-details outlined dense
              clearable :items="sucursalesFiltradas.filter(
                suc => suc.familia_id === data.familia
              )
                "></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" xl="4" class="py-1">
            Dirección Estación
            <v-text-field v-model.trim="data.direccion" hide-details outlined dense></v-text-field>
          </v-col>
          <v-col cols="11" sm="3" md="3" class="py-1">
            Imagen estación
            <v-autocomplete v-model="data.imagen" item-text="nombre" hide-details outlined dense clearable return-object
              :items="imagenes">
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>{{ item.nombre }}</v-list-item-content>
                  <v-list-item-icon small>
                    <v-icon small color="info">fas fa-image</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="data.imagen" cols="1" sm="1" md="1" class="py-1">
            <div style="display: flex; align-items: center; height: 100%;">
              <v-btn class="" small color="info" icon @click="toogleImagen" title="Ver imagen">
                <v-icon>fas fa-eye</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Monto
            <v-text-field-money v-model="data.monto" type="number" v-bind:properties="{
              prefix: '$',
              outlined: true,
              dense: true
            }"></v-text-field-money>
          </v-col>

          <v-col v-if="mostrarImagen" cols="12" sm="3" md="3" class="py-1">
            <img :src="urlImagenSeleccionada" :alt="nombreImagenSeleccionada">
          </v-col>
          <v-col cols="12" sm="2" md="12" class="py-0 mb-3">
            <div style="width: 100; display: flex; justify-content: end">
              <BtnConfirmar @action="guardar" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import TextFieldMoney from '../../../components/util/TextFieldMoney.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'

export default {
  data() {
    return {
      load: false,
      sucursalesFiltradas: [],
      empresasFiltradas: [],
      mostrarImagen: false,
      urlImagenSeleccionada: '',
      nombreImagenSeleccionada: '',

      data: {
        familia: null,
        empresa: null,
        sucursal: null,
        monto: null,
        imagen: {
          nombre: '',
          url: ''
        },
        direccion: ''
      },

      imagenes: []
    }
  },

  methods: {
    async guardar() {
      if (
        this.data.empresa &&
        this.data.familia &&
        this.data.sucursal &&
        this.data.monto
      ) {
        await this.$store
          .dispatch('vouchersCombustible/crearVoucher', {
            familia: this.data.familia,
            empresa: this.data.empresa,
            sucursal: this.data.sucursal,
            monto: this.data.monto,
            imagen: this.data.imagen.url,
            direccion: this.data.direccion
          })
          .then(res => {
            if (res.exito) {
              this.$emit('actualizar', true)
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.dialog = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'warning'
              })
            }
          })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Todos los campos son obligatorios.',
          color: 'warning'
        })
      }
    },

    setEmpresas() {
      const empresa = this.empresas.filter(
        emp => emp.familia === this.data.familia
      )
      empresa.length === 1 ? (this.data.empresa = empresa[0].id) : null
    },

    setSucursal() {
      const sucursal = this.sucursales.filter(
        suc => suc.familia_id === this.data.familia
      )
      sucursal.length === 1 ? (this.data.sucursal = sucursal[0].id) : null
    },

    abrirModalNuevoVoucher() {
      // ANTES DE ABRIR ESTE MODAL NECESITO SABER SI NO EXISTE UNO PARA ESA EMPRESA
      this.modalNuvoVoucher = true
    },

    toogleImagen() {
      this.mostrarImagen = !this.mostrarImagen
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias', 'empresas']),
    ...mapState('genericos', ['sucursales'])
  },

  props: {
    value: Boolean
  },

  watch: {
    async dialog(val) {
      if (!val) {
        this.data.direccion = ''
        this.data.empresa = null
        this.data.familia = null
        this.data.imagen = null
        this.data.monto = null

        this.nombreImagenSeleccionada = ''
        this.urlImagenSeleccionada = ''
        this.empresasFiltradas = []
        this.sucursalesFiltradas = []
      } else {
        this.empresasFiltradas = this.empresas
        this.sucursalesFiltradas = this.sucursales
        await this.$store
          .dispatch('vouchersCombustible/getImagenesEstaciones')
          .then(res => {
            if (res.exito) {
              this.imagenes = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
      }
    },

    'data.imagen'(val) {
      if (val) {
        this.nombreImagenSeleccionada = val.nombre
        this.urlImagenSeleccionada = val.url
      } else {
        this.urlImagenSeleccionada = ''
        this.nombreImagenSeleccionada = ''
      }
    }
  },

  created() {
    if (this.familias.length == 1) this.data.familia = this.familias[0].id
    this.sucursalesFiltradas = this.sucursales
    this.empresasFiltradas = this.empresas
  },

  components: {
    TextFieldMoney,
    BtnConfirmar
  }
}
</script>
